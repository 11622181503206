<template>
  <v-card>
    <v-card-text>
      <CustomReportTable v-if="customReportId" :customReportId="customReportId" @name="setName"> </CustomReportTable>
    </v-card-text>
  </v-card>
</template>

<script>
import CustomReportTable from "../../tables/CustomReportTable.vue";
export default {
  name: "Report",
  metaInfo: {
    title: "Reports"
  },
  components: { CustomReportTable },
  data() {
    return {
      customReportId: undefined,
      reportName: undefined
    };
  },
  methods: {
    setName(name) {
      this.reportName = name;
      this.breadcrumbs();
    },
    breadcrumbs() {
      this.$store.dispatch("setBreadcrumb", [
        {
          text: this.$i18n.translate("Dashboard"),
          to: { name: "dashboard" },
          exact: true
        },
        {
          text: this.$i18n.translate("Reports"),
          to: { name: "reports" },
          exact: true
        },
        { text: this.reportName ? this.reportName : "Report" }
      ]);
    }
  },
  mounted() {
    this.customReportId = parseInt(this.$route.params.id);
    this.breadcrumbs();
  }
};
</script>
